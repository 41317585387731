module["exports"] = [
    "Achim",
    "Adam",
    "Albu",
    "Aldea",
    "Alexa",
    "Alexandrescu",
    "Alexandru",
    "Alexe",
    "Andrei",
    "Anghel",
    "Antal",
    "Anton",
    "Apostol",
    "Ardelean",
    "Ardeleanu",
    "Avram",
    "Baciu",
    "Badea",
    "Balan",
    "Balint",
    "Banica",
    "Banu",
    "Barbu",
    "Barbulescu",
    "Bejan",
    "Biro",
    "Blaga",
    "Boboc",
    "Bodea",
    "Bogdan",
    "Bota",
    "Botezatu",
    "Bratu",
    "Bucur",
    "Buda",
    "Bunea",
    "Burlacu",
    "Calin",
    "Catana",
    "Cazacu",
    "Chiriac",
    "Chirila",
    "Chirita",
    "Chis",
    "Chivu",
    "Ciobanu",
    "Ciocan",
    "Cojocaru",
    "Coman",
    "Constantin",
    "Constantinescu",
    "Cornea",
    "Cosma",
    "Costache",
    "Costea",
    "Costin",
    "Covaci",
    "Cozma",
    "Craciun",
    "Cretu",
    "Crisan",
    "Cristea",
    "Cristescu",
    "Croitoru",
    "Cucu",
    "Damian",
    "Dan",
    "Danciu",
    "Danila",
    "Dascalu",
    "David",
    "Diaconescu",
    "Diaconu",
    "Dima",
    "Dinca",
    "Dinu",
    "Dobre",
    "Dobrescu",
    "Dogaru",
    "Dragan",
    "Draghici",
    "Dragoi",
    "Dragomir",
    "Dumitrache",
    "Dumitrascu",
    "Dumitrescu",
    "Dumitriu",
    "Dumitru",
    "Duta",
    "Enache",
    "Ene",
    "Farcas",
    "Filimon",
    "Filip",
    "Florea",
    "Florescu",
    "Fodor",
    "Fratila",
    "Gabor",
    "Gal",
    "Ganea",
    "Gavrila",
    "Georgescu",
    "Gheorghe",
    "Gheorghita",
    "Gheorghiu",
    "Gherman",
    "Ghita",
    "Giurgiu",
    "Grecu",
    "Grigoras",
    "Grigore",
    "Grigorescu",
    "Grosu",
    "Groza",
    "Horvath",
    "Iacob",
    "Iancu",
    "Ichim",
    "Ignat",
    "Ilie",
    "Iliescu",
    "Ion",
    "Ionescu",
    "Ionita",
    "Iordache",
    "Iorga",
    "Iosif",
    "Irimia",
    "Ispas",
    "Istrate",
    "Ivan",
    "Ivascu",
    "Kiss",
    "Kovacs",
    "Lazar",
    "Luca",
    "Lungu",
    "Lupu",
    "Macovei",
    "Maftei",
    "Man",
    "Manea",
    "Manolache",
    "Manole",
    "Marcu",
    "Marginean",
    "Marian",
    "Marin",
    "Marinescu",
    "Martin",
    "Mateescu",
    "Matei",
    "Maxim",
    "Mazilu",
    "Micu",
    "Mihai",
    "Mihaila",
    "Mihailescu",
    "Mihalache",
    "Mihalcea",
    "Milea",
    "Militaru",
    "Mircea",
    "Mirea",
    "Miron",
    "Miu",
    "Mocanu",
    "Moga",
    "Moise",
    "Moldovan",
    "Moldoveanu",
    "Molnar",
    "Morar",
    "Moraru",
    "Muntean",
    "Munteanu",
    "Muresan",
    "Musat",
    "Nagy",
    "Nastase",
    "Neacsu",
    "Neagoe",
    "Neagu",
    "Neamtu",
    "Nechita",
    "Necula",
    "Nedelcu",
    "Negoita",
    "Negrea",
    "Negru",
    "Nemes",
    "Nica",
    "Nicoara",
    "Nicolae",
    "Nicolescu",
    "Niculae",
    "Niculescu",
    "Nistor",
    "Nita",
    "Nitu",
    "Oancea",
    "Olariu",
    "Olaru",
    "Oltean",
    "Olteanu",
    "Oprea",
    "Opris",
    "Paduraru",
    "Pana",
    "Panait",
    "Paraschiv",
    "Parvu",
    "Pasca",
    "Pascu",
    "Patrascu",
    "Paun",
    "Pavel",
    "Petcu",
    "Peter",
    "Petre",
    "Petrea",
    "Petrescu",
    "Pintea",
    "Pintilie",
    "Pirvu",
    "Pop",
    "Popa",
    "Popescu",
    "Popovici",
    "Preda",
    "Prodan",
    "Puiu",
    "Radoi",
    "Radu",
    "Radulescu",
    "Roman",
    "Rosca",
    "Rosu",
    "Rotaru",
    "Rus",
    "Rusu",
    "Sabau",
    "Sandor",
    "Sandu",
    "Sarbu",
    "Sava",
    "Savu",
    "Serban",
    "Sima",
    "Simion",
    "Simionescu",
    "Simon",
    "Sirbu",
    "Soare",
    "Solomon",
    "Staicu",
    "Stan",
    "Stanciu",
    "Stancu",
    "Stanescu",
    "Stefan",
    "Stefanescu",
    "Stoian",
    "Stoica",
    "Stroe",
    "Suciu",
    "Szabo",
    "Szasz",
    "Szekely",
    "Tamas",
    "Tanase",
    "Tataru",
    "Teodorescu",
    "Toader",
    "Toma",
    "Tomescu",
    "Toth",
    "Trandafir",
    "Trif",
    "Trifan",
    "Tudor",
    "Tudorache",
    "Tudose",
    "Turcu",
    "Ungureanu",
    "Ursu",
    "Vaduva",
    "Varga",
    "Vasile",
    "Vasilescu",
    "Vasiliu",
    "Veres",
    "Vintila",
    "Visan",
    "Vlad",
    "Voicu",
    "Voinea",
    "Zaharia",
    "Zamfir"
];
