module["exports"] = [
    "Polokwane",
    "Johannesburg",
    "Pretoria",
    "Tshwane",
    "Durban",
    "Pietermaritzburg",
    "Nelspruit",
    "Cape Town",
    "Stellenbosch",
    "Port Elizabeth",
    "East London",
    "Kimberley",
    "Rustenburg",
    "Bloemfontein "
];
