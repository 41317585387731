var en = {};
module['exports'] = en;
en.title = "English";
en.separator = " & ";
en.address = require("./address");
en.company = require("./company");
en.internet = require("./internet");
en.database = require("./database");
en.lorem = require("./lorem");
en.name = require("./name");
en.phone_number = require("./phone_number");
en.cell_phone = require("./cell_phone");
en.business = require("./business");
en.commerce = require("./commerce");
en.team = require("./team");
en.hacker = require("./hacker");
en.app = require("./app");
en.finance = require("./finance");
en.date = require("./date");
en.system = require("./system");
en.vehicle = require("./vehicle");
