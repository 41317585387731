module["exports"] = {
    "adjective": [
      "Pequeño",
      "Ergonómico",
      "Rústico",
      "Inteligente",
      "Increible",
      "Fantástico",
      "Práctico",
      "Sorprendente",
      "Genérico",
      "Artesanal",
      "Hecho a mano",
      "Guapo",
      "Guapa",
      "Refinado",
      "Sabroso"
    ],
    "material": [
      "Acero",
      "Madera",
      "Plástico",
      "Algodón",
      "Granito",
      "Metal",
      "Ladrillo",
      "Hormigon"
    ],
    "product": [
      "Silla",
      "Coche",
      "Ordenador",
      "Teclado",
      "Raton",
      "Bicicleta",
      "Pelota",
      "Guantes",
      "Pantalones",
      "Camiseta",
      "Mesa",
      "Zapatos",
      "Gorro",
      "Toallas",
      "Sopa",
      "Atún",
      "Pollo",
      "Pescado",
      "Queso",
      "Bacon",
      "Pizza",
      "Ensalada",
      "Salchichas",
      "Patatas fritas"
    ]
  };
  