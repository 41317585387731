module["exports"] = [
    "+2760 ### ####",
    "+2761 ### ####",
    "+2763 ### ####",
    "+2772 ### ####",
    "+2773 ### ####",
    "+2774 ### ####",
    "+2776 ### ####",
    "+2779 ### ####",
    "+2781 ### ####",
    "+2782 ### ####",
    "+2783 ### ####",
    "+2784 ### ####",
    "082 ### ####",
    "084 ### ####",
    "083 ### ####",
    "065 ### ####",
    "082#######",
    "082 #######"
];