module["exports"] = [
"آزادی",
"آفریقا",
"آذربایجان",
"حقانی",
"امیرکبیر",
"اجاره دار",
"اقبال لاهوری",
"ابوذر",
"قدس",
"سباری",
"فاطمی",
"مالک اشتر",
"راستوان",
"دیباجی",
"واعظی",
"دستغیب",
"موحد دانش",
"کارگر شمالی",
"استاد قریب",
"یادگار امام",
"دکتر چمران",
"رسالت",
"سمیه",
"شهید مطهری",
"هویزه",
"دماوند",
];