module["exports"] = [
    "Achim",
    "Adam",
    "Adelin",
    "Adonis",
    "Adrian",
    "Adi",
    "Agnos",
    "Albert",
    "Alex",
    "Alexandru",
    "Alexe",
    "Aleodor",
    "Alin",
    "Alistar",
    "Amedeu",
    "Amza",
    "Anatolie",
    "Andrei",
    "Angel",
    "Anghel",
    "Antim",
    "Anton",
    "Antonie",
    "Antoniu",
    "Arian",
    "Aristide",
    "Arsenie",
    "Augustin",
    "Aurel",
    "Aurelian",
    "Aurică",
    "Avram",
    "Axinte",
    "Barbu",
    "Bartolomeu",
    "Basarab",
    "Bănel",
    "Bebe",
    "Beniamin",
    "Benone",
    "Bernard",
    "Bogdan",
    "Brăduț",
    "Bucur",
    "Caius",
    "Camil",
    "Cantemir",
    "Carol",
    "Casian",
    "Cazimir",
    "Călin",
    "Cătălin",
    "Cedrin",
    "Cezar",
    "Ciprian",
    "Claudiu",
    "Codin",
    "Codrin",
    "Codruț",
    "Cornel",
    "Corneliu",
    "Corvin",
    "Constantin",
    "Cosmin",
    "Costache",
    "Costel",
    "Costin",
    "Crin",
    "Cristea",
    "Cristian",
    "Cristobal",
    "Cristofor",
    "Dacian",
    "Damian",
    "Dan",
    "Daniel",
    "Darius",
    "David",
    "Decebal",
    "Denis",
    "Dinu",
    "Dominic",
    "Dorel",
    "Dorian",
    "Dorin",
    "Dorinel",
    "Doru",
    "Dragoș",
    "Ducu",
    "Dumitru",
    "Edgar",
    "Edmond",
    "Eduard",
    "Eftimie",
    "Emil",
    "Emilian",
    "Emanoil",
    "Emanuel",
    "Emanuil",
    "Eremia",
    "Eric",
    "Ernest",
    "Eugen",
    "Eusebiu",
    "Eustațiu",
    "Fabian",
    "Felix",
    "Filip",
    "Fiodor",
    "Flaviu",
    "Florea",
    "Florentin",
    "Florian",
    "Florin",
    "Francisc",
    "Frederic",
    "Gabi",
    "Gabriel",
    "Gelu",
    "George",
    "Georgel",
    "Georgian",
    "Ghenadie",
    "Gheorghe",
    "Gheorghiță",
    "Ghiță",
    "Gică",
    "Gicu",
    "Giorgian",
    "Grațian",
    "Gregorian",
    "Grigore",
    "Haralamb",
    "Haralambie",
    "Horațiu",
    "Horea",
    "Horia",
    "Iacob",
    "Iancu",
    "Ianis",
    "Ieremia",
    "Ilarie",
    "Ilarion",
    "Ilie",
    "Inocențiu",
    "Ioan",
    "Ion",
    "Ionel",
    "Ionică",
    "Ionuț",
    "Iosif",
    "Irinel",
    "Iulian",
    "Iuliu",
    "Iurie",
    "Iustin",
    "Iustinian",
    "Ivan",
    "Jan",
    "Jean",
    "Jenel",
    "Ladislau",
    "Lascăr",
    "Laurențiu",
    "Laurian",
    "Lazăr",
    "Leonard",
    "Leontin",
    "Lică",
    "Liviu",
    "Lorin",
    "Luca",
    "Lucențiu",
    "Lucian",
    "Lucrețiu",
    "Ludovic",
    "Manole",
    "Marcel",
    "Marcu",
    "Marian",
    "Marin",
    "Marius",
    "Martin",
    "Matei",
    "Maxim",
    "Maximilian",
    "Mădălin",
    "Mihai",
    "Mihail",
    "Mihnea",
    "Mircea",
    "Miron",
    "Mitică",
    "Mitruț",
    "Mugur",
    "Mugurel",
    "Nae",
    "Narcis",
    "Nechifor",
    "Nelu",
    "Nichifor",
    "Nicoară",
    "Nicodim",
    "Nicolae",
    "Nicolaie",
    "Nicu",
    "Nicuță",
    "Niculiță",
    "Nicușor",
    "Norbert",
    "Norman",
    "Octav",
    "Octavian",
    "Octaviu",
    "Olimpian",
    "Olimpiu",
    "Oliviu",
    "Ovidiu",
    "Pamfil",
    "Panait",
    "Panagachie",
    "Paul",
    "Pavel",
    "Pătru",
    "Petre",
    "Petrică",
    "Petrișor",
    "Petru",
    "Petruț",
    "Pompiliu",
    "Radu",
    "Rafael",
    "Rareș",
    "Raul",
    "Răducu",
    "Răzvan",
    "Relu",
    "Remus",
    "Robert",
    "Romeo",
    "Romulus",
    "Sabin",
    "Sandu",
    "Sava",
    "Sebastian",
    "Sergiu",
    "Sever",
    "Severin",
    "Silvian",
    "Silviu",
    "Simi",
    "Simion",
    "Sinică",
    "Sorin",
    "Stan",
    "Stancu",
    "Stelian",
    "Sandu",
    "Șerban",
    "Ștefan",
    "Teodor",
    "Teofil",
    "Teohari",
    "Theodor",
    "Tiberiu",
    "Timotei",
    "Titus",
    "Todor",
    "Toma",
    "Traian",
    "Tudor",
    "Valentin",
    "Valeriu",
    "Valter",
    "Vasile",
    "Vasilică",
    "Veniamin",
    "Vicențiu",
    "Victor",
    "Vincențiu",
    "Viorel",
    "Visarion",
    "Vlad",
    "Vladimir",
    "Vlaicu",
    "Voicu",
    "Zamfir",
    "Zeno"
];
