module["exports"] = [
    "#{male_first_name} #{last_name}",
    "#{male_first_name} #{last_name}",
    "#{male_first_name} #{last_name}",
    "#{male_first_name} #{last_name}",
    "#{male_first_name} #{last_name}",
    "#{male_first_name} #{last_name}",
    "#{last_name} #{male_first_name}",
    "#{last_name} #{male_first_name}",
    "#{last_name} #{male_first_name}",
    "#{last_name} #{male_first_name}",
    "#{last_name} #{male_first_name}",
    "#{last_name} #{male_first_name}",
    "#{prefix} #{male_first_name} #{last_name}",
    "#{prefix} #{male_first_name} #{last_name}",
    "#{prefix} #{male_first_name} #{last_name}",
    "#{male_first_name} #{last_name}, #{suffix}",
    "#{prefix} #{male_first_name} #{last_name}, #{suffix}",
    "#{female_first_name} #{last_name}",
    "#{female_first_name} #{last_name}",
    "#{female_first_name} #{last_name}",
    "#{female_first_name} #{last_name}",
    "#{female_first_name} #{last_name}",
    "#{female_first_name} #{last_name}",
    "#{last_name} #{female_first_name}",
    "#{last_name} #{female_first_name}",
    "#{last_name} #{female_first_name}",
    "#{last_name} #{female_first_name}",
    "#{last_name} #{female_first_name}",
    "#{last_name} #{female_first_name}",
    "#{prefix} #{female_first_name} #{last_name}",
    "#{prefix} #{female_first_name} #{last_name}",
    "#{prefix} #{female_first_name} #{last_name}"
];
