module["exports"] = [
  "تونس",
  "بن عروس",
  "أريانة",
  "باجة",
  "بنزرت",
  "قابس",
  "قفصة",
  "جندوبة",
  "القيروان",
  "القصرين",
  "قبلي",
  "الكاف",
  "المهدية",
  "منوبة",
  "مدنين",
  "المنستير",
  "نابل",
  "صفاقس",
  "بوزيد",
  "سليانة",
  "سوسة",
  "تطاوين",
  "توزر",
  "زغوان",
  "أدرار",
  "الشلف",
  "الأغواط",
  "أم البواقي",
  "باتنة",
  "بجاية",
  "بسكرة",
  "بشار",
  "البليدة",
  "البويرة",
  "تمنراست",
  "تبسة",
  "تلمسان",
  "تيارت",
  "تيزي وزو",
  "الجزائر",
  "الجلفة",
  "جيجل",
  "سطيف",
  "سعيدة",
  "سكيكدة",
  "بلعباس",
  "عنابة",
  "قالمة",
  "قسنطينة",
  "المدية"
];
